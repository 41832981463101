<template>
  <div>
      <b-row>
          <b-col cols="12">
              <b-card class="my-card m-0">
                  <b-table 
                  striped
                  small
                  id="myTables"
                  bordered
                  selectable
                  selected-variant="primary"
                  select-mode="single"
                  responsive="sm"
                  :items="village_types"
                  :fields="fields"
                  @row-dblclicked="editForm"
                  @row-clicked="clicked"
                  head-row-variant="secondary"
                  >
                  <template #cell(checkbox)="row">
                      <b-form-checkbox
                      v-model="row.detailsShowing"
                      plain
                      class="vs-checkbox-con"
                      @change="row.toggleDetails"
                      >
                      </b-form-checkbox>
                  </template>
                  <template #cell(is_active)="data">
                    <div>
                      {{ data.item.is_active === 1 ? 'Активен' : 'Не активен' }}
                    </div>
                  </template>
              </b-table>
          </b-card>
      </b-col>
      </b-row>
  </div>
      
  </template>
  <script>
  import { cellSelect } from "@/utils/TableFunctions/cellSelect";
  import { resizeable } from "@/utils/TableFunctions/resizeable.js";
  export default {
      props: ['village_types'],
      data(){
          return {
          fields: [ 
              { key: 'checkbox', label: '', thStyle: {width: '10px'}},
              { key: 'id', label: 'Id', sortable: true, thStyle: {width: '10px'}},
              { key: 'name', label: 'Наименование', sortable: true, thStyle: {width: '150px'}},
              { key: 'short_name', label: 'Короткое название', sortable: true, thStyle: {width: '150px'}},
              { key: 'is_active', label: 'Статус', sortable: true, thStyle: {width: '150px'}}
          ],
          }
      },
      mounted(){
          resizeable()
      },
      methods:{
          editForm(data){
              this.$emit('editForm', data.id)
          },
          refresh(){
              this.$emit('refresh')
          },
          clicked(item, index, event){
              this.$store.state.filterPlus.tableIndex = index
              cellSelect()
          },
      }
  }
  </script>
  
  <style lang="scss" scoped>
@import '@/assets/scss/table.scss';
  .my-card .card-body{
      padding: 0px;
  }
  .my-card{
  overflow: auto; 
  height: calc(100vh - 92px);
  }
  @media (max-height: 700px) {
    .my-card{
      overflow: auto; 
      height: 530px;
      }
  }
  </style>